import * as React from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import Layout from '../../components/layout'
import { connect } from 'react-redux'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import BrewerySummary from '../../Looking4Beer/Breweries/UseCases/BrewerySummary'
import { makeStyles } from '@material-ui/styles'
import { fetchBreweriesByLocation, fetchBreweriesByName, fetchMoreBreweries } from '../../state/breweriesSlice'

const useStyles = makeStyles({
  listContainer: {
    height: '500px',
  },
})

const ROW_SIZE = 90
const Index = ({ breweries, fetchBreweriesByName, fetchBreweriesByLocation, fetchMoreBreweries, isMoreAvailable }) => {

  const location = useLocation()
  const classes = useStyles()
  React.useEffect(() => {
    const { type, search } = queryString.parse(location.search)
    switch (type) {
      case 'city':
        // fetchBreweriesByCity(search)
        break
      case 'name':
      default:
        fetchBreweriesByName(search)
    }
  }, [])

  const Item = ({ index, style }) => {
    const brewery = breweries[index]
    if (!brewery) {
      return <div style={style}>Loading...</div>
    }
    return <BrewerySummary brewery={brewery} style={style}/>
  }
  const isItemLoaded = index => !!breweries[index]
  return (
    <Layout>
      <h1>Breweries List</h1>
      <div className={classes.listContainer}>
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={isMoreAvailable ? breweries.length + 20 : breweries.length}
              loadMoreItems={(startIndex, stopIndex) => fetchMoreBreweries({ startIndex, stopIndex })}
            >
              {({ onItemsRendered, ref }) => (
                <FixedSizeList
                  height={height}
                  width={width}
                  itemCount={breweries.length}
                  itemSize={ROW_SIZE}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                >
                  {Item}
                </FixedSizeList>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      </div>
    </Layout>
  )
}

const mapStateToProps = ({ breweries }) => {
  return {
    breweries: breweries.data,
    isMoreAvailable: breweries.hasNext,
  }
}

const mapDispatchToProps = {
  fetchBreweriesByName,
  fetchMoreBreweries,
  fetchBreweriesByLocation,
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)
